.annexesViewerMin img {
    max-width: 50px;
    max-height: 50px;
}

.bootstrap-tagsinput .badge {
    margin-right: 8px;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.drop-hover {
    border: 1px solid gray !important;
    background-color: lightgray !important;
}