a.badge {
    text-decoration: none;
}

.txt-status-new,.txt-status-import,.txt-status-draft,.txt-status-standby,.txt-status-proposed,.flag-status-new,.flag-status-import,.flag-status-draft,.flag-status-standby,.flag-status-proposed{
    color: orange !important;
}
.txt-status-warning,.flag-status-warning{
    color: #c98e57 !important;
}
.badge-status-warning{
    background-color: #c98e57 !important;
    color: black;
}
.badge-status-new,.badge-status-import,.badge-status-draft,.badge-status-standby,.badge-status-proposed{
    background-color: orange !important;
    color: black;
}
.btn-status-new,.btn-status-draft,.btn-status-new:hover,.btn-status-draft:hover,.btn-status-standby:hover,.btn-status-standby:hover{
    background-color: orange !important;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    border: 1px solid #c96304;
    color: white;
}
.txt-status-active,.txt-status-avalise,.flag-status-avalise,.flag-status-active{
    color: #3CB521  !important;
}
.badge-status-active,.badge-status-avalise{
    background-color: #3CB521  !important;
    color: white;
}
.txt-status-inactif,.txt-status-inactifdef,.txt-status-desactive,.flag-status-desactive,.txt-status-delete,.flag-status-delete,.flag-status-inactif,.flag-status-inactifdef{
    color: #b10000 !important;
}
.badge-status-inactif,.badge-status-desactive,.badge-status-delete,.badge-status-inactifdef{
    background-color: #b10000 !important;
    color: white;
}
.txt-status-inactif{
    text-decoration: line-through;
}
.txt-status-inactifdef{
    text-decoration: line-through;
    font-weight: 800;
}

.txt-status-effectue{
    color: #3CB521 !important;
}
.btn-status-effectue,.btn-status-effectue:hover{
    -webkit-filter: none;
    filter: none;
    border: 1px solid #dbdbdb;
    color: #3CB521;
}
.ico-status-effectue{
    font-size: 1.4em;
    padding:3px;
    color: #3CB521;
}
.btn-status-effectue-actif,.btn-status-effectue-actif:hover{
    -webkit-filter: none;
    filter: none;
    border: 1px solid #dbdbdb;
    background-color: #3CB521;
    color: white;
}
.txt-status-reussi{
    color: #3CB521 !important;
}
.btn-status-reussi,.btn-status-reussi:hover{
    -webkit-filter: none;
    filter: none;
    border: 1px solid #dbdbdb;
    color: #3CB521;
}
.ico-status-reussi{
    font-size: 1.4em;
    padding:3px;
    color: #3CB521;
}
.btn-status-reussi-actif,.btn-status-reussi-actif:hover{
    -webkit-filter: none;
    filter: none;
    border: 1px solid #dbdbdb;
    background-color: #3CB521;
    color: white;
}

.txt-status-effectuepart{
    color: #2e8a19 !important;
}
.btn-status-effectuepart,.btn-status-effectuepart:hover{
    -webkit-filter: none;
    filter: none;
    border: 1px solid #dbdbdb;
    color: #2e8a19;
}
.ico-status-effectuepart{
    color: #2e8a19;
    font-size: 1.4em;
    padding:3px;
}
.btn-status-effectuepart-actif,.btn-status-effectuepart-actif:hover{
    -webkit-filter: none;
    filter: none;
    border: 1px solid #dbdbdb;
    background-color: #2e8a19;
    color: white;
}
.txt-status-noneffectue{
    color: #6b0000 !important;
}
.btn-status-noneffectue,.btn-status-noneffectue:hover{
    -webkit-filter: none;
    filter: none;
    border: 1px solid #dbdbdb;
    color: #6b0000;
}
.ico-status-noneffectue{
    font-size: 1.4em;
    padding:3px;
    color: #6b0000;
}
.btn-status-noneffectue-actif,.btn-status-noneffectue-actif:hover{
    -webkit-filter: none;
    filter: none;
    border: 1px solid #dbdbdb;
    background-color: #6b0000;
    color: white;
}
.btn-status-effectue:hover,.btn-status-noneffectue:hover,.btn-status-effectuepart:hover{
    background-color: #dbdbdb;
    -webkit-filter: none;
    filter: none;
}
.btn-status-resetvalide,.btn-status-resetvalide:hover{
    -webkit-filter: none;
    filter: none;
    border: 1px solid #dbdbdb;
    color: #cccccc;
    
}
.btn-screenmin{
    padding: 0.175rem 0.50rem;
}

.btn-status-avalise,.btn-status-avalise:hover{
    /*background-image: linear-gradient(#61dd45, #3CB521 50%, #36a41e);*/
    background-color: #3CB521;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    border: 1px solid #2e8a19;
    color: white;
}

.btn-status-active,.btn-status-active:hover{
    /*background-image: linear-gradient(#61dd45, #3CB521 50%, #36a41e);*/
    background-color: #0f5000;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    border: 1px solid #0d4600;
    color: white;
}

.btn-status-inactif,.btn-status-inactif:hover{
    /*background-image: linear-gradient(#61dd45, #3CB521 50%, #36a41e);*/
    background-color: #720000;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    border: 1px solid #6b0000;
    color: white;
}


.btn-status-desactive,.btn-status-desactive:hover{
    /*background-image: linear-gradient(#61dd45, #3CB521 50%, #36a41e);*/
    background-color: #720000;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    border: 1px solid #6b0000;
    color: white;
}

.txt-status-valide{
    color: blue !important;
}
.txt-strikethrough{
    text-decoration: line-through;
}
.badge-status-valide{
    background-color: blue !important;
    color: white;
}
.btn-status-valide,.btn-status-valide:hover{
    background-color: blue !important;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    border: 1px solid #1648a5;
    color: white;
}

.txt-status-invalide{
    color: #c20aa9 !important;
}
.badge-status-invalide{
    background-color: #c20aa9 !important;
    color: white;
}
.btn-status-invalide,.btn-status-invalide:hover{
    background-color: #c20aa9 !important;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    border: 1px solid #c000a6;
    color: white;
}

.txt-status-refuse{
    color: #c564c5 !important;
}
.badge-status-refuse{
    background-color: #c564c5 !important;
    color: black;
}
.btn-status-refuse,.btn-status-refuse:hover{
    background-color: #c564c5 !important;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    border: 1px solid #c20aa9;
    color: white;
}

.txt-status-cancel{
    color: #352453 !important;
}
.badge-status-cancel{
    background-color: #352453 !important;
    color: white;
}
.btn-status-cancel,.btn-status-cancel:hover{
    background-color: #352453 !important;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    border: 1px solid #261a3b;
    color: white;
}

.txt-status-muted{
    color: #dbdbdb !important;
}

.txt-status-grey{
    color: #636363;
}

.txt-status-deleted{
    text-decoration: line-through;
}

.bg-tr-success {
    background-color: #f2fcf2;
}

.txt-type-REMARK {
    color: #c564c5 !important;
}