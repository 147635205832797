body {
    background-color: white;
    font-size: .875rem;
}
.text-arborescence{
  color: #ccc;
}
.agent_photos img {
    max-width: 80px;
}
.agentFicheAvatar {
    max-width: 150px;
}
.noavatar {
    margin: 20px;
}
.photoshow {
    max-width: 300px;
}
.showpage_actionbuttons{
    margin-top:10px;
    margin-bottom: 10px;
}
#camcontainer{
    text-align: center;
}
#cam, #canvas{
    position: relative;
    width: 640px; 
    height: 480px;
    margin-right: auto;
    margin-left: auto;
    display: none;
    margin-bottom: 10px;
}
#cam {
    display: block;
}
form[name=agent_photos_cam] .form-group {
    display: none;
}
#filtre {
    width:500px;
    position: absolute;
    top: 0px;
    left: 70px;
}
input {
    /*margin-top: 50px;*/
}
video {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    }
#countdown {
    display: block;
    width: 150px;
    height: 100px;
    font-size: 8em;
    position: absolute;
    bottom: 100px;
    left: 245px;
    color:chartreuse;
    text-align: center;
    vertical-align: bottom;
    margin-top: auto;
}
#text_avert {
    position: absolute;
    width: 640px;
    top: 2px;
    font-size: 2em;
    font-weight: bold;
    color:chartreuse;
    text-align: center;
    display: none;
}
#startphoto_container {
    position: absolute;
    top: 350px;
    width: 640px;
}
#snap,#snap:visited {
    color: white;
}
#snapoff,#snapoff:visited {
    color: white;
}
#snapvalid {
    color: white;
    display:none;
}
#snapreload {
    color: white;
    display:none;
}
#snapdownload{
    color: black;
    display:none;    
}

/*
 * Sidebar
 */

 .sidebarico {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }

  .sidebar .actionbar {
      margin-top: 5px;
      margin-bottom: 15px;
  }

 .sidebar {
    /*position: fixed;
    top: 0;
    bottom: 0;*/
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    padding-left: 10px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
      position: -webkit-sticky;
      position: sticky;
    }
  }
  
  .sidebar .nav-link {
    font-weight: 500;
    color: #333;
  }
  
  .sidebar .nav-link .sidebarico {
    margin-right: 4px;
    color: #000;
  }
  
  .sidebar .nav-link.active {
    color: #007bff;
  }
  
  .sidebar .nav-link:hover .sidebarico,
  .sidebar .nav-link.active .sidebarico {
    color: inherit;
  }
  
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }


  .btn-seance,.btn-seance:hover{
    background-color: lightgray !important;
    background-repeat: no-repeat;
    -webkit-filter: none;
    filter: none;
    color: white;
  }

  #presences-dayselector button {
    border: 1px solid #333;
    margin-right: 10px;
  }

  /*
   * Content
   */
  
  [role="main"] {
    padding-top: 133px; /* Space for fixed navbar */
  }
  
  @media (min-width: 768px) {
    [role="main"] {
      padding-top: 48px; /* Space for fixed navbar */
    }
  }



  